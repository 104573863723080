import request from '@/utils/request'

export default{
 
  //登录
  login(data) {
    return request({
      url: '/api/setup/login',
      method: 'post',
      data
    })
  },
  //退出
  logout() {
    return request({
      url: '/api/setup/logout',
      method: 'get'
    })
  },
  checkToken(data) {
    return request({
      url: '/api/setup/checkToken',
      method: 'post',
      data
    })
  },
  addGame(data) {
    return request({
      url: '/api/setup/addGame',
      method: 'post',
      data
    })
  },
  deleteGame(id) {
    return request({
      url: '/api/setup/deleteGame/'+id,
      method: 'get'
    })
  },
  listGame(data,size,page) {
    return request({
      url: '/api/setup/listGame/'+size +'/'+page,
      method: 'post',
      data
    })
  },
  listGamePlate() {
    return request({
      url: '/api/setup/listGamePlate',
      method: 'get'
    })
  },
  listGameByGamePlate(data) {
    return request({
      url: '/api/setup/listGameByGamePlate',
      method: 'post',
      data
    })
  },
  addDiscount(data) {
    return request({
      url: '/api/setup/addDiscount',
      method: 'post',
      data
    })
  },
  deleteDiscount(id) {
    return request({
      url: '/api/setup/deleteDiscount/'+id,
      method: 'get'
    })
  },
  updateDiscount(data) {
    return request({
      url: '/api/setup/updateDiscount',
      method: 'post',
      data
    })
  },
  listDiscount(data,size,page) {
    return request({
      url: '/api/setup/listDiscount/'+size +'/'+page,
      method: 'post',
      data
    })
  },
  addAdminAccount(data) {
    return request({
      url: '/api/setup/addAdminAccount',
      method: 'post',
      data
    })
  },
  deleteAdminAccount(id) {
    return request({
      url: '/api/setup/deleteAdminAccount/'+id,
      method: 'get'
    })
  },
  updateAdminAccount(data) {
    return request({
      url: '/api/setup/updateAdminAccount',
      method: 'post',
      data
    })
  },
  listAdminAccount() {
    return request({
      url: '/api/setup/listAdminAccount',
      method: 'get'
    })
  },
  listOrder(data,size,page) {
    return request({
      url: '/api/setup/listOrder/'+size +'/'+page,
      method: 'post',
      data
    })
  },
  queryOrderStatus(orderNo) {
    return request({
      url: '/api/setup/queryOrderStatus/' + orderNo,
      method: 'get'
    })
  },
  addGameDiscount(data) {
    return request({
      url: '/api/setup/addGameDiscount',
      method: 'post',
      data
    })
  },
  deleteGameDiscount(id) {
    return request({
      url: '/api/setup/deleteGameDiscount/'+id,
      method: 'get'
    })
  },
  updateGameDiscount(data) {
    return request({
      url: '/api/setup/updateGameDiscount',
      method: 'post',
      data
    })
  },
  listGameDiscount(data,size,page) {
    return request({
      url: '/api/setup/listGameDiscount/'+size +'/'+page,
      method: 'post',
      data
    })
  },

  addPlatePassword(data) {
    return request({
      url: '/api/setup/addPlatePassword',
      method: 'post',
      data
    })
  },
  deletePlatePassword(id) {
    return request({
      url: '/api/setup/deletePlatePassword/'+id,
      method: 'get'
    })
  },
  updatePlatePassword(data) {
    return request({
      url: '/api/setup/updatePlatePassword',
      method: 'post',
      data
    })
  },
  listPlatePassword() {
    return request({
      url: '/api/setup/listPlatePassword',
      method: 'get'
    })
  },


  addScrollMessage(data) {
    return request({
      url: '/api/setup/addScrollMessage',
      method: 'post',
      data
    })
  },
  deleteScrollMessage(id) {
    return request({
      url: '/api/setup/deleteScrollMessage/'+id,
      method: 'get'
    })
  },
  listScrollMessage() {
    return request({
      url: '/api/setup/listScrollMessage',
      method: 'get'
    })
  },


  addLotteryGame(data) {
    return request({
      url: '/api/setup/addLotteryGame',
      method: 'post',
      data
    })
  },
  deleteLotteryGame(id) {
    return request({
      url: '/api/setup/deleteLotteryGame/'+id,
      method: 'get'
    })
  },
  updateLotteryGame(data) {
    return request({
      url: '/api/setup/updateLotteryGame',
      method: 'post',
      data
    })
  },
  listLotteryGame(data,size,page) {
    return request({
      url: '/api/setup/listLotteryGame/'+size +'/'+page,
      method: 'post',
      data
    })
  },

  addPrizeGrantRecord(data) {
    return request({
      url: '/api/setup/addPrizeGrantRecord',
      method: 'post',
      data
    })
  },
  updatePrizeGrantRecord(data) {
    return request({
      url: '/api/setup/updatePrizeGrantRecord',
      method: 'post',
      data
    })
  },
  listPrizeGrantRecord(data,size,page) {
    return request({
      url: '/api/setup/listPrizeGrantRecord/'+size +'/'+page,
      method: 'post',
      data
    })
  },
  getLotteryGameList() {
    return request({
      url: '/api/setup/getLotteryGameList',
      method: 'get'
    })
  },
  getPrizeGrantRecordList() {
    return request({
      url: '/api/setup/getPrizeGrantRecordList',
      method: 'get'
    })
  },
  getPrizeGrantRecord(data) {
    return request({
      url: '/api/setup/getPrizeGrantRecord',
      method: 'post',
      data
    })
  },
  awardLogin(data) {
    return request({
      url: '/api/setup/awardLogin',
      method: 'post',
      data
    })
  },
  getLotteryTimes(data) {
    return request({
      url: '/api/setup/getLotteryTimes',
      method: 'post',
      data
    })
  },
  getLotteryGame(data) {
    return request({
      url: '/api/setup/getLotteryGame',
      method: 'post',
      data
    })
  },


  addRewardContent(data) {
    return request({
      url: '/api/setup/addRewardContent',
      method: 'post',
      data
    })
  },
  deleteRewardContent(id) {
    return request({
      url: '/api/setup/deleteRewardContent/'+id,
      method: 'get'
    })
  },
  listRewardContent(data) {
    return request({
      url: '/api/setup/listRewardContent',
      method: 'post',
      data
    })
  },
  getRewardContentList() {
    return request({
      url: '/api/setup/getRewardContentList',
      method: 'get'
    })
  },
  updateRewardContent(data) {
    return request({
      url: '/api/setup/updateRewardContent',
      method: 'post',
      data
    })
  },



  //红包雨
  addRedPackageBase(data) {
    return request({
      url: '/api/setup/addRedPackageBase',
      method: 'post',
      data
    })
  },
  deleteRedPackageBase(id) {
    return request({
      url: '/api/setup/deleteRedPackageBase/'+id,
      method: 'get'
    })
  },
  updateRedPackageBase(data) {
    return request({
      url: '/api/setup/updateRedPackageBase',
      method: 'post',
      data
    })
  },
  listRedPackageBase(data,size,page) {
    return request({
      url: '/api/setup/listRedPackageBase/'+size +'/'+page,
      method: 'post',
      data
    })
  },

  addRedPackageGame(data) {
    return request({
      url: '/api/setup/addRedPackageGame',
      method: 'post',
      data
    })
  },
  deleteRedPackageGame(id) {
    return request({
      url: '/api/setup/deleteRedPackageGame/'+id,
      method: 'get'
    })
  },
  updateRedPackageGame(data) {
    return request({
      url: '/api/setup/updateRedPackageGame',
      method: 'post',
      data
    })
  },
  listRedPackageGame(data,size,page) {
    return request({
      url: '/api/setup/listRedPackageGame/'+size +'/'+page,
      method: 'post',
      data
    })
  },
  getRedPackageGameList() {
    return request({
      url: '/api/setup/getRedPackageGameList',
      method: 'get'
    })
  },



  addRedPackageRecord(data) {
    return request({
      url: '/api/setup/addRedPackageRecord',
      method: 'post',
      data
    })
  },
  deleteRedPackageRecord(id) {
    return request({
      url: '/api/setup/deleteRedPackageRecord/'+id,
      method: 'get'
    })
  },
  updateRedPackageRecord(data) {
    return request({
      url: '/api/setup/updateRedPackageRecord',
      method: 'post',
      data
    })
  },
  listRedPackageRecord(data,size,page) {
    return request({
      url: '/api/setup/listRedPackageRecord/'+size +'/'+page,
      method: 'post',
      data
    })
  },


  addRedPackageRule(data) {
    return request({
      url: '/api/setup/addRedPackageRule',
      method: 'post',
      data
    })
  },
  deleteRedPackageRule(id) {
    return request({
      url: '/api/setup/deleteRedPackageRule/'+id,
      method: 'get'
    })
  },
  listRedPackageRule(data) {
    return request({
      url: '/api/setup/listRedPackageRule',
      method: 'post',
      data
    })
  },
  getRedPackageRuleList() {
    return request({
      url: '/api/setup/getRedPackageRuleList',
      method: 'get'
    })
  },
  updateRedPackageRule(data) {
    return request({
      url: '/api/setup/updateRedPackageRule',
      method: 'post',
      data
    })
  },

//领取次数
getTimes(data) {
    return request({
      url: '/api/setup/getTimes',
      method: 'post',
      data
    })
  },

  //领取剩余次数
  getResidueNum(data) {
  return request({
    url: '/api/setup/getResidueNum',
    method: 'post',
    data
  })
},



addRedPackageUser(data) {
  return request({
    url: '/api/setup/addRedPackageUser',
    method: 'post',
    data
  })
},
deleteRedPackageUser(id) {
  return request({
    url: '/api/setup/deleteRedPackageUser/'+id,
    method: 'get'
  })
},
updateRedPackageUser(data) {
  return request({
    url: '/api/setup/updateRedPackageUser',
    method: 'post', 
    data
  })
},
listRedPackageUser(data,size,page) {
  return request({
    url: '/api/setup/listRedPackageUser/'+size +'/'+page,
    method: 'post',
    data
  })
},

getRedPackageGameByGameId(gameId) {
  return request({
    url: '/api/setup/getRedPackageGameByGameId/'+gameId,
    method: 'get'
  })
},
getRedPackageRecord(gameId,type) {
  return request({
    url: '/api/setup/getRedPackageRecord/'+gameId +'/'+type,
    method: 'get'
  })
},

getRedPackageUser(data) {
  return request({
    url: '/api/setup/getRedPackageUser',
    method: 'post', 
    data
  })
},

getUserInfoByToken(data) {
  return request({
    url: '/api/setup/getUserInfo',
    method: 'post', 
    data
  })
},
}