<template>
	<div id="draw-background">
		<div style="background-color: rgb(167, 219, 180);">
			<p style="font-size: 20px; color: rgb(32, 71, 229);margin-top: 1px;">当前账号：{{userName}}</p>
			<span style="font-size: 20px; color: rgb(32, 71, 229); margin-top: 10px;">当前游戏：</span>
			<el-select class="option-choise" :popper-append-to-body="false" v-model="gameId" filterable placeholder="请选择抽奖游戏" style="width: 70%;" @change="getNew">
				<el-option
				v-for="item in lotteryGameList"
				:key="item.gameId"
				:label="item.gameName"
				:value="item.gameId">
				</el-option>
			</el-select>
		</div>
		<div class="notice-content" :style="{ animationDuration: duration + 's' }">
			<div v-for="(item, index) in notices" :key="index" class="notice-item">
				{{ item }}	
			</div>
      	</div>
		<div id="lucky">
			<LuckyWheel
				ref="myLucky"
				width="300px"
				height="300px"
				:prizes="prizes"
				:blocks="blocks"
				:buttons="buttons"
				:default-config="defaultConfig"
				@start="startCallback"
				@end="endCallback"
			/>
		</div>
		<div id="today-prize-grant-record" style="margin-top: -20px;">
			<span @click="getPrizeGrantRecord" style="font-size: 20px; color: rgb(228, 33, 33); border-radius: 8px; background-color: aliceblue;" >中奖记录</span>
		</div>
		<div id="reward-times" style="margin-top: 10px;">
			<p style="font-size: 15px; color: rgb(240, 234, 234); margin-top: 10px; text-align:center">今日充值{{totalAmount}}元，剩余{{times}}次抽奖</p>
		</div>
		<div id="reward-money">
			<p style="font-size: 15px; color: rgb(247, 240, 240); margin-top: 10px; text-align:center">当前游戏每充值{{money}}元获得一次抽奖次数</p>
		</div>
		<div id="reward-content" style="margin-top: -10px;">
				<span><br></span>
				<span class="content-start">活动奖励内容<br></span>
				<span><br></span>
				<div v-for="(item, index) in rewardContentList" :key="index" class="traffic-box">
    				<span class="item-reward-content">{{ item }}<br></span>
				</div>
				<span><br></span>
				<span  class="content-end">最终解析权归E兔玩平台所有，详情请咨询<a class="registerUrl" href="https://work.weixin.qq.com/kfid/kfc298fd74660766c12" style="font-size: 15px;">在线客服</a></span>
		</div>
	
		<el-dialog :visible.sync="dialogList" class="dialog-list" append-to-body>
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 20px;">中奖记录</div>
			</template>
			<!-- 列表页 -->
			<el-table :data="todayPrizeGrantRecordList">
				<el-table-column prop="prizeName" label="奖品名称" width="100">
				</el-table-column>
				<el-table-column prop="prizeNum" label="奖品数量" width="100">
				</el-table-column>
				<el-table-column prop="createTime" label="中奖时间" width="100">
				</el-table-column>
			</el-table>
		</el-dialog>
		
		<el-dialog :visible.sync="dialogCreate" :rules="caseRules" ref='createRef' class="create" append-to-body>
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 15px;">恭喜你中得{{realArticel}},请填写邮件信息,并联系客服</div>
			</template>
			<el-form :model="prizeGrantRecord" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item label="姓名" prop="realName">
					<el-input v-model="prizeGrantRecord.realName"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="phone">
					<el-input v-model="prizeGrantRecord.phone"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input v-model="prizeGrantRecord.address"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
  
  <script>
  import manageApi from '../api/manage'

  export default {
	name: 'Award',
	data () {
	  return {
		token: '',
		currentIndex: 0,
		duration: 10, // 每条公告滚动的时间
		notices: [],//滚动消息

		todayPrizeGrantRecordList: [],//今日中奖记录
		totalAmount:'',//今日充值总额
		lotteryGameList: [],
		rewardContentList: [],
		money: '',
		openId: '',
		userName: '',
		gameId: '',
		times: '',
		realArticel: '',
		dialogList: false,
		dialogCreate: false,
		prizeGrantRecord: {
			userName: '',
			gameId: '',
			prizeType: '',
			prizeName: '',
			prizeNum: '',
			realName: '',
			phone: '',
			address: '',
			openId: ''
		},
		caseRules: {
			realName: [{
				required: true,
				message: '真实姓名不能为空',
				trigger: 'blur'
			}
			],
			phone: [{
					required: true,
					message: '电话号码不能为空',
					trigger: 'blur'
				}
			],
			address: [{
					required: true,
					message: '地址不能为空',
					trigger: 'blur'
				}
			]
		},
		blocks: [
			{ padding: '10px', background: '#d64737' },
			{ padding: '0px', background: '#fff' },
		],
		buttons: [
			{ radius: '40px', background: '#d64737' },
			{ radius: '35px', background: '#f6c66f', pointer: true },
			{
				radius: '30px',
				background: '#fff',
				fonts: [
					{ text: '开始\n抽奖', top: '-20px' }
				]
			}
      	],
		defaultConfig: { gutter: '5px' },
		prizes: []
	  }
	},
	created() {
 		// 从url中获取token,如果token不为空，则表示是用URL请求过来的，
		// 若为空，则是用公众号里进入的，走原来公众号的逻辑
		this.token = this.$route.query.token;
		console.log('token:',this.token)
		if (this.token !== null && this.token !== undefined) {
			// 游戏ID也从URL中拿
			this.gameId = this.$route.query.gameId
			this.openId = this.$route.query.openId
			// 还需要根据token从后端获取到玩家用户名称和openId
			this.getUserInfoByToken(this.token);
			if (this.userName === '') {
				this.$message({
					message: '未获取到登录用户',
					type: 'error',
					duration: 2000
				});
				return;
			}
		} else {
			if (this.loginIsExpired()) {
				this.$message({
					message: '登录已超时，请重新登录',
					type: 'error',
					duration: 2000,
					onClose:()=>{
						this.$router.push('/awardLogin')
					}
				});
				return;
			} else {

				// this.userName = 'h281428';  
				// this.openId = 'oTi-P6mUF6bZbDxFcETXrcI5vAFw'; 

				this.userName = window.localStorage.getItem('userName');  
				this.openId = window.localStorage.getItem('openId'); 

			}
		}

		//获取抽奖游戏列表
		this.getLotteryGameList()
		//获取最近10条抽奖记录用以滚动播报
		this.getPrizeGrantRecordList()
		// 启动定时器，每隔一段时间切换公告
		setInterval(this.moveToNextNotice, this.duration )
		
	},
	methods: {
		getUserInfoByToken(token) {
			manageApi.getUserInfoByToken({token:token}).then((res)=>{
				if (res.code === 200) {
					this.userName = res.data.userName
				}
			})
		},
		loginIsExpired() {
			let obj = window.localStorage.getItem('expireTime');
			if (!obj) {
				return true;
			}
			obj = JSON.parse(obj);
			if (Date.now() - obj.time > obj.expire) {
				localStorage.removeItem('expireTime');
				return true;
			}
			return false;
		},
		moveToNextNotice() {
			this.currentIndex++;
			if (this.currentIndex >= this.notices.length) {
				this.currentIndex = 0;
			}
		},

        // 选择游戏，重新获取抽奖信息
		getNew() {
			//根据游戏ID获取抽奖游戏
			this.getLotteryGame()
			//获取剩余抽奖次数
			this.getLotteryTimes()
			//获取活动奖励内容
			this.getRewardContent()
		},

		//获取最近10条抽奖记录用以滚动播报
		getPrizeGrantRecordList() {
			manageApi.getPrizeGrantRecordList().then((res) => {
				if (res.code === 200) {
					this.notices = res.data.list
				}
			})
		},
		// 获取个人今日中奖记录
		getPrizeGrantRecord() {
			manageApi.getPrizeGrantRecord({gameId:this.gameId, userName:this.userName}).then((res) => {
				if (res.code === 200) {
					this.todayPrizeGrantRecordList = res.data.prizeGrantRecordList
				}
			})
			this.dialogList = true
		},
		//获取抽奖游戏列表
		getLotteryGameList() {
			manageApi.getLotteryGameList().then((response) => {
				this.lotteryGameList = response.data.lotteryGameList
				this.gameId = this.lotteryGameList[0].gameId
				//根据游戏ID获取抽奖游戏
				this.getLotteryGame()
				//获取剩余抽奖次数
				this.getLotteryTimes()
				//获取活动奖励内容
				this.getRewardContent()
			})
		},
		getRewardContent() {
			manageApi.listRewardContent({gameId:this.gameId}).then((res)=>{
				if (res.data.rewardContentList !== null && res.data.rewardContentList.length>0) {
					this.rewardContentList = res.data.rewardContentList
					console.log('res',this.rewardContentList)
				}
			})
		},

		getLotteryTimes() {
			manageApi.getLotteryTimes({gameId:this.gameId, userName:this.userName}).then((res)=>{
				var lotteryTimes = res.data.lotteryTimesOne;
				if (lotteryTimes === null) {
					this.totalAmount = 0
					this.times = 0
				} else {
					this.totalAmount = lotteryTimes.accumulatedAmount
					this.times = lotteryTimes.residueNum
				}
			})
		},
		getLotteryGame() {
			this.prizes = []
			//根据游戏ID获取抽奖游戏
			manageApi.getLotteryGame({gameId:this.gameId, userName:this.userName}).then((response) => {
				var lotteryGameList = response.data.lotteryGameList
				for(var i=0;i<lotteryGameList.length;i++) {
					var lotteryGame = lotteryGameList[i];
					this.money = lotteryGame.thresholdMoney
					var background
					if (i%2==0) {
						background = '#b8c5f2'
					} else {
						background = '#e9e8fe'
					}
					var src = '/images/fudai.png';
					if ('现金红包' === lotteryGame.prizeType) {
						src = '/images/hongbao.png'
                    }
					const awardObject = {
						fonts: [{text: lotteryGame.prizeName, top: '10%', fontColor: '#FF7002', fontWeight: 600, wordWrap: false, lineHeight: '0.613rem'}],
						imgs: [{src: src,top: '2.38rem', width: '4.3rem', height: '4.4rem'}],
						background: background,
						range: lotteryGame.winningProbability,
						type: lotteryGame.prizeType,
						num: lotteryGame.prizeNum,
						cdkCode: lotteryGame.cdkCode
					}
					this.prizes.push(awardObject)
				}
			})
		},
		submit() {
			if (this.prizeGrantRecord.realName === undefined || this.prizeGrantRecord.phone === undefined || this.prizeGrantRecord.address === undefined) {
				this.$message({
					message: '姓名、电话、地址不能为空',
					type: 'error',
					duration: 1000
				});
				return;
			}
			this.create()
		},
		close() {
			this.dialogCreate = false
			this.prizeGrantRecord.userName = ''
			this.prizeGrantRecord.gameId = ''
			this.prizeGrantRecord.prizeType = ''
			this.prizeGrantRecord.prizeName = ''
			this.prizeGrantRecord.prizeNum = ''
			this.prizeGrantRecord.realName = ''
			this.prizeGrantRecord.phone = ''
			this.prizeGrantRecord.address = ''
			//根据游戏ID获取抽奖游戏
			this.getLotteryGame()
			// 刷新剩余的抽奖次数
			this.getLotteryTimes()
			//获取最近10条抽奖记录用以滚动播报
			this.getPrizeGrantRecordList()
		},
		create() {
			manageApi.addPrizeGrantRecord(this.prizeGrantRecord).then((res)=>{
				if (res.code === 200) {
					// 关闭弹框
					this.close()
				} else {
					this.$message({
					message: res.data.message,
					type: 'error',
					duration: 1000
					});
				}
			})
		},
	  // 点击抽奖按钮会触发star回调
	  startCallback () {
		// 点击抽奖之前先看登录是否到期
		if (this.loginIsExpired()) {
			this.$message({
				message: '登录已超时，请重新登录',
				type: 'error',
				duration: 2000,
				onClose:()=>{
					this.$router.push('/awardLogin')
				}
			});
			
			return;
		}
	
		if (!this.times || this.times === 0) {
			alert(`无抽奖次数`)
			return;
		}
		// 调用抽奖组件的play方法开始游戏
		this.$refs.myLucky.play()
		// 模拟调用接口异步抽奖
		setTimeout(() => {
		  // 假设后端返回的中奖索引是0
		  //   const index = 0
		  // 调用stop停止旋转并传递中奖索引
		  this.$refs.myLucky.stop()
		}, 3000)
	  },
	  // 抽奖结束会触发end回调
	  endCallback (prize) {
			console.log('prize:',prize.fonts)
			var prizeName = prize.fonts[0].text;
			this.prizeGrantRecord = {
					userName: this.userName,
					gameId: this.gameId,
					prizeType: prize.type,
					prizeName: prizeName,
					prizeNum: prize.num,
					openId: this.openId,
					cdkCode: prize.cdkCode
				}
			if (prize.type === '平台币') {
				alert(`恭喜你获得${prize.num}个平台币,系统自动充值`)
				this.create()
			} else if (prize.type === '游戏币') {
				alert(`恭喜你获得${prize.num}个游戏币,系统自动充值`)
				this.create()
			} else if (prize.type === '现金红包') {
				alert(`恭喜你获得${prize.fonts[0].text}${prize.num}元,稍后公众号领取红包`)
				this.create()
			} else if (prize.type === '实物') {
				this.dialogCreate = true
			    this.realArticel = prizeName;
			}else if (prize.type === 'CDK(礼包)') {
				alert(`恭喜你获得${prize.fonts[0].text}:${prize.cdkCode}`)
				this.create()
			} else {
				this.create()
			}
	  },
	}
  }
  </script>

<style scoped>

	#draw-background{
		background: url("../assets/img/beijingtupian.png");
		width: 100%;
		height: 100%;
		position: fixed;
		background-size: 100% 100%;
	}
	 #lucky{
		margin-right: auto;
		margin-left: 50px;
	}
	
	#reward-times{
		border-radius: 10px;
		background-color: rgb(179, 95, 102);
		width: 80%;
		height: 5%;
		margin-left: 35px;
		border: 1px solid black;
	}
	#reward-times p {
		text-align: center;
	}
	
	#reward-money{
		width: 90%;
		height: 5%;
		margin-left: 15px;
	}
	
	#reward-content{
		border-radius: 10px;
		background-color: rgb(241, 229, 216);
		width: 90%;
		height: 33%;
		margin-left: 20px;
	}
	
	.create {
		width: 180%;
		margin: 1px 10px 50px -160px;
    }

	.content-start{
		display: block;  
    	margin: auto;  
    	text-align: center;
		font-size: 20px;
	}
	.content-end{
		display: block;  
    	margin: auto;  
    	text-align: center;
		font-size: 15px;
	}
	.item-reward-content{
		display: block;  
    	margin: 0px 10px auto;  
		font-size: 13px;
	} 

	.notice-content {
		display: flex;
		white-space: nowrap;
		animation-name: scroll;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.notice-item {
		flex-shrink: 0;
		font-size: 20px;
	}

	@keyframes scroll {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-100%);
		}
	}

	.dialog-list{
		width: 700px;
		margin: 10px 100px 100px -150px;
	}

</style>

