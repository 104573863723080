<template>
	<div class="login_box">
		<div class="title">
				<img src = "../assets/img/etuwan.png" width="150px" height="150px">
				<p style="color: rgb(207, 22, 22);">E兔玩综合福利中心</p>
			</div>
		<el-card class="box-card">
			
			<a class="registerUrl" href="http://m.etuw.cn/#/pages/views/register/index" style="font-size: 25px;">注册</a>
			<el-form :model="formLogin" :rules="loginRules" ref='loginRef' @keydown.enter.native="loginHandle">
				<el-form-item prop="username" label="游戏账号 :" required>
					<el-input v-model="formLogin.username" prefix-icon="el-icon-user" placeholder="请输入游戏账号"/>
				</el-form-item>
				<el-form-item prop="password" label="密码 :" required>
					<el-input v-model="formLogin.password" type='password' prefix-icon="el-icon-lock"
						placeholder="请输入密码"/>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="loginHandle">点击登录</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>
 
<script>
	import manageApi from "../api/manage"
	import wxPayApi from '../api/wxPay'

	export default {
		data() {
			return {
				 // appid: 'wx39d47973f29306cf',//公众号ID-测试的
				appid: 'wx0710d9e3f6746926',//开发者ID(AppID)
				// appsecret: '7ea4ebc68c90bf6042150aa6600b110f',--测试的
				appsecret: '3d4ad64be5e9c0a762db642d6a9f6924',//开发者密码(AppSecret)
				openId: '',
				formLogin: {
					username: '',
					password: ''
				},
				loginRules: {
					username: [{
						required: true,
						message: '账号不能为空',
						trigger: 'blur'
					}
				   ],
					password: [{
							required: true,
							message: '密码不能为空',
							trigger: 'blur'
						}
					]
				}
			}
		},

		created() {
			// 获取微信公众号OPENID
			let code= this.getUrlKey("code");
			console.log('code',code)
			if(code){
				this.getOpenId(code)
			}else{
				this.getCodeApi();
			}
		},
		methods: {
			getCodeApi(){//获取code   
				let urlNow=encodeURIComponent(window.location.href);
				console.log('urlNow',urlNow)
				// let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
				let url= 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.appid+'&redirect_uri='+urlNow+'&response_type=code&scope=snsapi_base#wechat_redirect';
				window.location.href = url
			},
			getUrlKey(name){//获取url 参数
				return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			},

			getOpenId(code) {
				wxPayApi.getOpenId(code).then((res) => {
					console.log('getOpenId res',res)
					this.openId = res
				})
			},
			// 点击登录之后，处理登录的方法
			loginHandle() {
				if (this.formLogin.username.length === 0 || this.formLogin.password.length === 0) {
					this.$message.error("账号和密码不能为空");
					return
				}
				manageApi.awardLogin(this.formLogin).then((response) => {
					if (response && response.code === 200) {
						window.localStorage.setItem('userName',this.formLogin.username);
						window.localStorage.setItem('openId',this.openId);
						// 设置6个小时的超时时间
						let obj = {
							time: Date.now(),
							expire: 21600000
						}
						window.localStorage.setItem('expireTime',JSON.stringify(obj));
						this.$router.push('/indexPage')
					} else {
						this.$message.error(response.data.message);
					}
      			})
			}
		},
		// 组件中的数据挂载到模板中之后，会触发这个生命周期钩子函数
		// mounted(){
		// 	// 获取localStorage中的账号,设置到data中
		// 	const username = window.localStorage.getItem('username')
		// 	if(username){
		// 		this.formLogin.username = username
		// 	}
		// }
	}
</script>
 
<style scoped>
	/**
	 * 在style中，添加scoped属性，表示css样式，只对当前组件生效。
	 */
	.login_box {
		position: relative;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
	}
 
	.title {
		color: #409eff;
		font: bold 28px/60px "microsoft yahei";
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
	}

	.logonout {
		width: 60px;
		color: #fff;
		font: normal 14px/60px "microsoft yahei";
		float: right;
		text-align: center;
	}

	.login_box a {
		position: absolute;  
		top: 0;  
		right: 0;
		font: normal 14px/60px "microsoft yahei";
	}

</style>