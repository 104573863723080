<template>
    <div class="red-packet-rain">
		<div style="background-color: rgb(167, 219, 180);">
			<p style="font-size: 20px; color: rgb(32, 71, 229);margin-top: 1px;">当前账号：{{userName}}</p>
			<span style="font-size: 20px; color: rgb(32, 71, 229); margin-top: 10px;">当前游戏：</span>
			<el-select class="option-choise" :popper-append-to-body="false" v-model="gameId" filterable placeholder="请选择游戏" style="width: 70%;" @change="getNew">
				<el-option
				v-for="item in redPackageGameList"
				:key="item.gameId"
				:label="item.gameName"
				:value="item.gameId">
				</el-option>
			</el-select>
		</div>
		
		<!-- 红包区域 -->
		<ul class="box" id="boxBag">
			<span class="redPackageRule" style="font-size: 25px;" @click="gotoRulePage" >活动规则>></span>
			<p class="redPackageTimes" style="font-size: 20px; color: rgb(209, 37, 42); text-align:center">当前剩余抢红包次数：{{this.residueNum}}</p>
			<span class="redPackageMoreTimes" style="font-size: 25px;" @click="getMoreTimes" >获取更多次数>></span>
			<span class="rank" style="font-size: 25px;" @click="gotoRankPage" >查看榜单>></span>
		</ul>
		<!-- 获奖弹框 -->
		<div class="popwin" id="popwin" style="display: none;">
			<h3>获得红包<em></em></h3>
			<button type="button">确定</button>
		</div>
		<!-- 开抢倒计时 -->
		<div class="countdown" id="countDom"  >
			<span>{{times}}</span>
		</div>	
		<el-dialog :visible.sync="dialogGet" style="width: 500px; " class="dialog-list" append-to-body>
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 20px; color: red;">领取次数</div>
			</template>
			<div class="abc">
				<el-button type="primary" @click="getTimes(0)" style="margin-left: 10px;">本周充值{{minMoney}}元领取次数</el-button>
				<el-button type="primary" @click="getTimes(1)" style="margin-top: 10px;">本周充值{{midMoney}}元领取次数</el-button>
				<el-button type="primary" @click="getTimes(2)" style="margin-top: 10px;">本周充值{{maxMoney}}元领取次数</el-button>
				<!-- <div style="margin-top: 20px; margin-left: 10px;">
					<a class="registerUrl" href= "getUrl()" style="font-size: 20px; ">游戏链接>></a>
				</div>
				 -->
			</div>
		</el-dialog>

		<el-dialog :visible.sync="dialogRank" style="width: 500px;margin-left: -50px; " class="dialog-list2" append-to-body>
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 20px; color: red;">选择榜单</div>
			</template>
			<div class="abc2">
				<el-button type="primary" @click="getRank(0)" style="margin-left: 60px;">本周榜单</el-button>
				<el-button type="primary" @click="getRank(1)" style="margin-left: 60px;margin-top: 50px;">历史榜单</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>

import manageApi from '../api/manage'

export default {
  name: 'redPackageYu',
  data () {
    return {
		token: '',
		showRule: true,//是否显示活动规则
		showGetTimes: true,//是否显示获取更多抽奖次数
		dialogGet: false,//是否显示领取次数页面
		dialogRank: false,//是否显示榜单页面
		redPackageGameList: [],//抽奖游戏列表
        gameId: '',//游戏ID
		times: '',//倒计时的秒数
		time: 0,//红包雨持续时间
		residueNum: 0,//剩余抽奖次数
		userName: '',//玩家账号
		openId: '',//微信OPENID
		url: '',//游戏链接-未设置
		redPackageBasePage: '',//抽奖游戏基本设置
		totalNum: 0,//总的产生红包的个数，是设置的时间的4倍
		type: 1,//产生红包的类型，默认是1，即第一个产生的是现金红包，由于现金和平台币各50%，所以交叉产生现金红包和平台币红包
		money: 0,//抽中的总现金数
		gamebi: 0,//抽中的总平台币数
		num: 0,//抢到红包的个数
		startTime:'',//设置的红包雨开始时间
		moneyMin: 0,//红包最小金额
		moneyMax: 0,//红包最大金额
		gamebiMin: 0,//平台币最小个数
		gamebiMax: 0,//平台币最大个数
		minMoney: 0,//最小充值金额
		midMoney: 0,//第二充值金额
		maxMoney: 0,//最大充值金额
		gameCoinProbability: 0,//平台币中奖几率
		redPackageUserList: [],//内定玩家
		userSize: 0,//内定玩家设置了几天记录
		formData: {
			redPackageNum: '',
			redPackageProbability: '',
			gameCoinNum: '',
			gameCoinProbability: '',
			startTime: '',
			time: '',
			maxTimes: ''
		},
	}
  },
  created() {
	// 从url中获取token,如果token不为空，则表示是用URL请求过来的，
	// 若为空，则是用公众号里进入的，走原来公众号的逻辑
	this.token = this.$route.query.token;
	if (this.token !== null && this.token !== undefined) {
		// 游戏ID也从URL中拿
		this.gameId = this.$route.query.gameId
		this.openId = this.$route.query.openId
		// 还需要根据token从后端获取到玩家用户名称和openId
		this.getUserInfoByToken(this.token);
		if (this.userName === '') {
			this.$message({
				message: '未获取到登录用户',
				type: 'error',
				duration: 2000
			});
			return;
		}
	} else {
		if (this.loginIsExpired()) {
			this.$message({
				message: '登录已超时，请重新登录',
				type: 'error',
				duration: 2000,
				onClose:()=>{
					this.$router.push('/awardLogin')
				}
			});
			return;
		} else {
			this.userName = window.localStorage.getItem('userName');  
			this.openId = window.localStorage.getItem('openId');
			// this.userName = '18304993903'
			// this.openId = 'oTi-123456789'
			
			
		}
	}
	//获取抽奖游戏列表
	this.getRedPackageGameList()
	// 获取红包雨基本设置
	this.getRedPackageBase()
  },
  methods: {
	getUserInfoByToken(token) {
			manageApi.getUserInfoByToken({token:token}).then((res)=>{
				if (res.code === 200) {
					this.userName = res.data.userName
				}
			})
		},
	// 获取内定玩家
	getRedPackageUser() {
		manageApi.getRedPackageUser({gameId:this.gameId,userName:this.userName,openId:this.openId}).then((res)=>{
			this.redPackageUserList = res.data.redPackageUserList;
			this.userSize = res.data.redPackageUserList.length;
		})
	},
	getRedPackageBase(){
		manageApi.listRedPackageBase(this.formData,1,1).then((res)=>{
			this.redPackageBasePage = res.data.redPackageBasePage.records[0];
			this.totalNum = this.redPackageBasePage.time*4;
			this.time = this.redPackageBasePage.time;
			this.startTime = this.redPackageBasePage.startTime
			this.gameCoinProbability = this.redPackageBasePage.gameCoinProbability
			console.log('totalNum',this.totalNum)
			console.log("this.time:", this.time)
			console.log("this.startTime:", this.startTime)

            // 现金红包
			var redPackageNum = this.redPackageBasePage.redPackageNum;
			const dateArray = redPackageNum.split("-");
			this.moneyMin = dateArray[0];
			this.moneyMax = dateArray[1];
           // 平台币
			var gameCoinNum = this.redPackageBasePage.gameCoinNum;
			const dateArray2 = gameCoinNum.split("-");
			this.gamebiMin = dateArray2[0];
			this.gamebiMax = dateArray2[1];

			// 若当前时间和红包雨开启时间相差1分钟，则开始倒计时，否则只显示红包雨的开始时间
			// 获取当前时间
			let currentTime = new Date();
			console.log("currentTime:", currentTime+"-"+currentTime.getTime())
			let targetTime = new Date(this.startTime);
			console.log("targetTime:", targetTime+"-"+targetTime.getTime())
			console.log("targetTime2:", this.time+targetTime.getTime())
			if (currentTime.getTime()>this.time*1000+targetTime.getTime()) {
				this.times = this.startTime + '开始'
				this.$message({
				message: '本周红包雨活动已结束，请下周再来。',
				type: 'error',
				duration: 2000
				});
				return;
			}
			let timeDiffInMilliseconds = Math.abs(targetTime - currentTime)/1000;
			console.log("timeDiffInMilliseconds:", timeDiffInMilliseconds)
			if (currentTime.getTime()>targetTime.getTime()) {
				//删除倒计时区域
				var countDom = document.getElementById('countDom')
				countDom.remove();
				this.showRule = false;
				this.showGetTimes = false;
				this.totalNum = (this.time- parseInt(timeDiffInMilliseconds))*4;
				console.log("红包雨持续中：", this.totalNum)
				//添加红包
				this.addRedPacket();
			} else if (timeDiffInMilliseconds <= 60) {
				this.times = parseInt(timeDiffInMilliseconds)
				console.log("倒计时开始")
				this.backword();
			} else {
				this.times = this.startTime + '开始'
			}
			
		})
	},
	backword() {
		console.log("倒计时开始")
		if(this.times>=2) {
			this.times--;
			setTimeout(this.backword,1000);   
		}else {
			//删除倒计时区域
			var countDom = document.getElementById('countDom')
			countDom.remove();
			console.log("倒计时结束")
			this.showRule = false;
			this.showGetTimes = false;
			//添加红包
			this.addRedPacket();
		}
	},
	getUrl() {
		return "http://page.etuw.cn/?game_id="+this.gameId+"&client_id=20706"
	},
	getTimes(val){
		manageApi.getTimes({gameId:this.gameId,userName:this.userName,openId:this.openId,timesType:val}).then((res)=>{
			if (res.code === 200) {
				this.$message({
					type: 'success',
					message: res.data.message,
					duration: 1000
				});
				// 更新页面的数据
				this.getResidueNum()
				// 关闭弹框
				this.dialogGet = false
			} else {
				this.$message({
				message: res.data.message,
				type: 'error',
				duration: 2000
				});
			}
		})
	},
	
	getMoreTimes() {
		this.dialogGet = true
	},
	getResidueNum(){
		manageApi.getResidueNum({gameId:this.gameId,userName:this.userName}).then((res)=>{
			var redPackageTimes = res.data.redPackageTimes;
				if (redPackageTimes === null) {
					this.residueNum = 0
				} else {
					this.residueNum = redPackageTimes.residueNum
				}
		})
	},
	// 去规则页面
    gotoRulePage() {
		this.$router.push({
			name: "rule",
			params: {gameId:this.gameId}
		});
    },
	// 去榜单页面
	gotoRankPage() {
		this.dialogRank = true
	},
	getRank(val){
		this.$router.push({
        name: "rank",
        params: {gameId:this.gameId,type:val}
      });
	},
	getRedPackageRule() {
		manageApi.listRewardContent({gameId:this.gameId}).then((res)=>{
			if (res.data.redPackageRuleList !== null && res.data.redPackageRuleList.length>0) {
				this.redPackageRuleList = res.data.redPackageRuleList
				console.log('res',this.redPackageRuleList)
			}
		})
	},
	getRedPackageGameList() {
		manageApi.getRedPackageGameList().then((response) => {
				this.redPackageGameList = response.data.redPackageGameList
				this.gameId = this.redPackageGameList[0].gameId
				// 获取内定玩家
				this.getRedPackageUser()
				//获取剩余抽奖次数
				this.getResidueNum()
				this.getRedPackageGameByGameId()
			})
	},

	addRedPackageRecord(money, gamebi) {
		console.log('调用接口发红包或发平台币')

		return new Promise((resolve, reject)=> {
			manageApi.addRedPackageRecord({gameId:this.gameId, userName:this.userName, openId:this.openId,money:money,gamebi:gamebi}).then((res)=>{
				// 获取红包雨基本设置
				// this.getRedPackageBase()
				
				if (res.code === 200) {
					resolve(true);
				} else {
					reject(false);
				}
			})
		})
	},

	// 选择游戏，重新获取抽奖信息
	getNew() {
			// 获取内定玩家
			this.getRedPackageUser()
			//获取剩余抽奖次数
			this.getResidueNum()
			this.getRedPackageGameByGameId()
		},

	getRedPackageGameByGameId() {
		manageApi.getRedPackageGameByGameId(this.gameId).then((res) => {
			if (res.code === 200) {
				var game = res.data.redPackageGame;
				this.minMoney = game.minMoney;
				this.midMoney = game.midMoney;
				this.maxMoney = game.maxMoney;
			} else {
				this.$message({
				message: res.data.message,
				type: 'error',
				duration: 1000
				});
			}
		})
	},

	// 增加红包
    addRedPacket(){
		console.log("开始生成红包", this.type)
		if (this.totalNum === 0) {
			this.showRule = true;
			this.showGetTimes = true;
			// // 红包雨结束后统一调用接口发红包或发平台币
			this.$message({
				message: '抢红包次数已用完!共抢到'+this.num+'个红包，现金'+this.money+'元，平台币'+this.gamebi+'个。',
				type: 'error',
				duration: 3000
			});
			this.$router.go(0);
			return;
		}
		var liDom = document.createElement('li');
		// 哪个红包图片  
		var hb =Math.ceil(Math.random()*2) ;     // 0 - 2   -   >  1- 2   hb_1  hb_2
		
		// 红包大小   20-60
		var Wh = Math.random() * 40 + 20;     // 0 -40  ---- > 20-- 60

		//红包的位置  0%  - 100%  
		var Left = Math.random() * 100 +'%';

		// 旋转角度  - 45deg -    45deg
		var rot = (Math.random()*90 - 45) ;    // 0--90  -> -45 - 45
		// 红包金额  0 -100 
		if (this.gameCoinProbability == 0) {
			var money1 = this.getMoney()
			if (money1 === undefined) {
				money1 = this.moneyMin
			}
			liDom.setAttribute('data-money',money1);
		} else {
			console.log('都生成')
			if (this.type === 1) {
				var money = this.getMoney()
				if (money === undefined) {
					money = this.moneyMin
				}
				liDom.setAttribute('data-money',money);
			} else {
				var gamebi = this.getGameBi()
				if (gamebi === undefined) {
					gamebi = this.gamebiMin
				}
				liDom.setAttribute('data-money',gamebi);
			}
		}
		
		liDom.setAttribute('data-type',this.type);
		liDom.innerHTML = '<img src="images/hb_'+hb+'.png">';
		liDom.style.cssText = `width:${Wh}px;left:${Left};transform:rotate(${rot}deg)`;  //es6模板字符串

		console.log('liDom', liDom)
		document.getElementById('boxBag').appendChild(liDom);

		this.totalNum = this.totalNum-1;
		if (this.gameCoinProbability != 0) {
			if (this.type === 1) {
			this.type = 2
			} else {
				this.type = 1
			}
		}

		setTimeout(this.addRedPacket,250);
		//删除已经不在屏幕显示区的红包
		setTimeout(function(){
			liDom.remove();
		},5000);
		var popwin = document.getElementById('popwin')
		//点击红包到红包时 弹出模态框
		let me = this;
		liDom.addEventListener('click',function(ev){
			console.log('ev',ev)
			if (me.residueNum == 0) {
				me.$message({
				message: '抢红包次数已用完!',
				type: 'info',
				duration: 2000
				});
				return;
			}
			me.num = me.num + 1;
			me.residueNum = me.residueNum - 1;
			var type = ev.target.parentElement.dataset.type;
			console.log('type',ev.target.parentElement.dataset)
			if (me.userSize == 0) {
				if (type === '1') {
					var money =  ev.target.parentElement.dataset.money;
					if (money === undefined) {
						money = me.moneyMin
					}
					console.log('money',money)
					me.addRedPackageRecord(money,0).then(data=>{
						console.log('Data fetched:', data);
						me.money = parseInt(me.money)+parseInt(money);
						popwin.querySelector('em').innerText = money+'元';
						popwin.style.display = 'block';
					}).catch(error=>{
						console.error('Error fetching data:', error);
						me.$message({
							message: '抢红包次数为0,请刷新页面!',
							type: 'warning',
							duration: 2000
						});
					})
				} else {
					var gamebi =  ev.target.parentElement.dataset.money;
					if (gamebi === undefined) {
						gamebi = me.gamebiMin
					}
					console.log('gamebi',gamebi)
					me.addRedPackageRecord(0,gamebi).then(data=>{
						console.log('Data fetched:', data);
						me.gamebi = parseInt(me.gamebi)+parseInt(gamebi);
						popwin.querySelector('em').innerText = gamebi+'个平台币';
						popwin.style.display = 'block';
					}).catch(error=>{
						console.error('Error fetching data:', error);
						me.$message({
							message: '抢红包次数为0,请刷新页面!',
							type: 'warning',
							duration: 2000
						});
					})
				}
			} else {
				var redPackageUser = me.redPackageUserList[me.num-1];
				me.userSize = me.userSize-1;
				var redType = redPackageUser.type;
				if ("红包" === redType) {
					var money1 = redPackageUser.num;
					me.addRedPackageRecord(money1,0).then(data=>{
						console.log('Data fetched:', data);
						me.money = parseInt(me.money)+parseInt(money1);
						popwin.querySelector('em').innerText = money1+'元';
						popwin.style.display = 'block';
					}).catch(error=>{
						console.error('Error fetching data:', error);
						me.$message({
							message: '抢红包次数为0,请刷新页面!',
							type: 'warning',
							duration: 2000
						});
					})
				} else {
					var gamebi1 = redPackageUser.num;
					me.addRedPackageRecord(0,gamebi1).then(data=>{
						console.log('Data fetched:', data);
						me.gamebi = parseInt(me.gamebi)+parseInt(gamebi1);
						popwin.querySelector('em').innerText = gamebi1+'个平台币';
						popwin.style.display = 'block';
					}).catch(error=>{
						console.error('Error fetching data:', error);
						me.$message({
							message: '抢红包次数为0,请刷新页面!',
							type: 'warning',
							duration: 2000
						});
					})
				}
			}
			
		})
		//点击确认关闭模态框
		popwin.querySelector('button').onclick = function(){
			popwin.style.display = 'none';
		}
	},

	getMoney() {
		return this.getRandomIntInRange(this.moneyMin,this.moneyMax);
	},
	getGameBi() {
		return this.getRandomIntInRange(this.gamebiMin,this.gamebiMax);
	},

	getRandomIntInRange(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},

	loginIsExpired() {
			let obj = window.localStorage.getItem('expireTime');
			if (!obj) {
				return true;
			}
			obj = JSON.parse(obj);
			if (Date.now() - obj.time > obj.expire) {
				localStorage.removeItem('expireTime');
				return true;
			}
			return false;
		},
  }
}
</script>
<style>
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  height: 100vh;
  background: url(../assets/img/bj.jpg) top left no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
}
.box li {
  position: absolute;
  animation: all 30s linear;
  top: -100px;
  animation: redRun 10s;
}
@keyframes redRun {
  0% {
    top: -10%;
  }
  100% {
    top: 110%;
  }
}
.popwin {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2) url(../assets/img/gx.png) center 16% no-repeat;
  background-size: 300px;
  z-index: 2;
  left: 0;
  top: 0;
  padding-top: 400px;
  text-align: center;
  font-size: 24px;
  color: rgb(29, 204, 47);
}
.popwin button {
  background: chocolate;
  color: #fff;
  padding: 10px 30px;
  border: 0;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 5px;
}
.countdown {
  width: 90%;
  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.2);
  height: 50%;
  display: flex;
  position: absolute;
  margin-left: 20px;
  margin-top: 35px;
}
.countdown span {
  color: #6e231c;
  font-weight: bold;
  font-size: 25px;
  margin: auto;
}
.box .redPackageRule {
	position: absolute;  
	top: 0;  
	right: 0;
	font: normal 14px/60px "microsoft yahei";
	margin-top: 50px;
	color: rgb(66, 66, 225);
}

.redPackageTimes {
	position: fixed;
	bottom: 0;
	margin-bottom: 200px;
}

.box .redPackageTimes {
	border-radius: 10px;
	background-color: rgb(246, 204, 204);
	width: 70%;
	height: 5%;
	margin-left: 55px;
	border: 1px solid black;
	display: flex;
	align-items: center;
}
.redPackageMoreTimes {
	position: fixed;
	bottom: 0;
	margin-bottom: 150px;
}


.box .redPackageMoreTimes {
	position: absolute;  
	font: normal 14px/60px "microsoft yahei";
	color: rgb(66, 66, 225);
	margin-left: 100px;
}

.rank {
	position: fixed;
	bottom: 0;
	margin-bottom: 90px;
}

.box .rank {
	position: absolute;  
	font: normal 14px/60px "microsoft yahei";
	margin-left: 100px;
	color: rgb(66, 66, 225);
	right: 0;
}

.abc{
	width: 200px;
	height: 200px;
	justify-content: center;
    align-items: center;
}
.abc2{
	width: 200px;
	height: 200px;
	justify-content: center;
    align-items: center;
}
.dialog-list {
	margin-left: -50px;
}
</style>